import React, { useState, useEffect, useContext } from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import DatePicker from 'components/DatePicker';
import AlertModal from 'components/AlertModal';
import { post } from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import config from 'config';
import { getCookie } from 'helpers';
import moment, { Moment } from 'moment';
import { InsurerPractices, Request, Session } from 'types/Request';
import { UserContext } from 'context';
import { useHistory } from 'react-router-dom';

const insurersWithToken = [
  57, // Swiss (excepto internaciones)
  74, // Federada (excepto internaciones)
];

const insurersWithCoinsurance = [
  46, // UP
  57, // Swiss
  77, // OSPe
];

const insurersWithPreviousAuthorization = [
  22, // COMEI
  26, // AMFFA
  55, // Medife
  57, // Swiss
  77, // OSPe
];

const insurersAnnulsAllSessions = [
  46, // UP
];

interface SessionsProps {
  requestId: string;
  formData: Request;
  prestaciones: InsurerPractices;
  isLoading: boolean;
  disableSend: boolean;

  setLoading: (value: boolean) => void;
  setSessions: (sessions: Session[]) => void;
  setRecipient: (name: string) => void;
  setFormData?: (formData: Request) => void;
}

export default function Sessions(props: SessionsProps) {
  const {
    setSessions,
    formData,
    setFormData,
    isLoading,
    setLoading,
    disableSend,
    requestId,
    setRecipient,
    prestaciones } = props;

  const user = useContext(UserContext);
  const history = useHistory();

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');

  const [isEnabled, setEnabled] = useState(false);

  const [havePreviousAuthorization, setHavePreviousAuthorization] = useState(false);
  const [requiredPreviousAuthorization, setRequiredPreviousAuthorization] = useState(false);
  const [enablePreviousAuthorization, setEnablePreviousAuthorization] = useState(false);
  const [previousAuthorizationLabel, setPreviousAuthorizationLabel] = useState('');

  const [showToken, setShowToken] = useState(false);
  const [showCoinsurance, setShowCoinsurance] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOriginal, setIsOriginal] = useState(false);
  const [newSessionMinDate, setNewSessionMinDate] = useState<Date>();
  const [newSessionMaxDate, setNewSessionMaxDate] = useState<Date>();
  const [newSession, setNewSession] = useState<{
    date: Date | null;
    token: string | null;
    authorization_id: string | null;
  }>({
    date: null,
    token: null,
    authorization_id: null,
  });
  const [quotaExceeded, setQuotaExceeded] = useState(false);
  const [excludedDates, setExcludedDates] = useState<Date[]>([]);

  const [annulsAllSessions, setAnnulsAllSessions] = useState(false);

  useEffect(() => {
    const newExcludedDates: Date[] = [];

    for (const currentSession of formData.sessions) {
      let count = 0;

      // Contamos la cantidad de veces que la fecha de la sesión se cargó
      for (const targetSession of formData.sessions) {
        if (moment(currentSession.date).isSame(targetSession.date, 'day')) {
          count += 1;
        }
      }

      // Obtenemos la cantidad de sesiones que se pueden cargar por día
      let sessionsPerDay = 1;
      if (formData.insurer_id === 57 && (formData.practice_id === 14 || formData.additional_id === 14)) {
        sessionsPerDay = 2;
      } else if (formData.insurer_id === 55 && (formData.practice_id === 14 || formData.additional_id === 14)) {
        sessionsPerDay = 3;
      }

      // Si se cargó menos vecs que el máximo no la excluimos
      if (count < sessionsPerDay) {
        return;
      }

      // Otros casos los agregamos a las fechas a excluir
      newExcludedDates.push(currentSession.date);
    }

    setExcludedDates(newExcludedDates);
  }, [formData.sessions]);

  // Seteamos la etiqueta para autorizaciones previas
  useEffect(() => {
    switch (formData.insurer_id) {
      case 22: // COMEI
        if (quotaExceeded || !!formData.extended_quota) {
          setPreviousAuthorizationLabel('Ingrese Disposición');
        } else {
          setPreviousAuthorizationLabel('Autorización Discapacidad');
        }
        break;
      case 26: // AMFFA
        setPreviousAuthorizationLabel('Autorización Previa');
        break;
      case 55: // Medife
        if (formData.impairment) {
          setPreviousAuthorizationLabel('Autorización Tratamiento de Discapacidad');
        } else if (formData.extended_quota) {
          setPreviousAuthorizationLabel('Número de aprobación');
        } else {
          setPreviousAuthorizationLabel('Autorización Previa');
        }
        break;
      case 57: // Swiss
        if (formData.impairment) {
          setPreviousAuthorizationLabel('Autorización Previa (Para Discapacidad)');
        } else {
          setPreviousAuthorizationLabel('Autorización Previa (Para Especialidades)');
        }
        break;
      case 77: // OSPe
        setPreviousAuthorizationLabel('Validado por OSPE');
        break;
      default:
        setPreviousAuthorizationLabel('Autorización Previa');
    }
  }, [formData.insurer_id]);

  useEffect(() => {
    let withoutPrevAuth = false; // Permite desactivar en casos específicos la autorización previa
    let withToken = insurersWithToken.includes(formData.insurer_id);
    switch (formData.insurer_id) {
      case 22: // COMEI
        if (formData.previously_authorized || quotaExceeded) { // Requere autorización previa o extención de cupo
          withoutPrevAuth = !!formData.authorization_id;
          setPreviousAuthorizationLabel('Ingrese Disposición');
          formData.extended_quota = !!quotaExceeded;
        } else {
          withoutPrevAuth = true;
        }

        withToken = false;
        break;
      case 26: // AMFFA
        // Si es RPG pedimos número autorización si no hay uno guardado
        if (formData.practice_id == 9) {
          withoutPrevAuth = !!formData.authorization_id;

        // Todas las demás prácticas no llevan autorización
        } else {
          withoutPrevAuth = true;
        }
        break;
      case 39:
        // Discapacidad requiere número de autorización sólo la primera vez, después lo recupera de la solicitud
        if ( formData.impairment ) {
          withoutPrevAuth = !!formData.authorization_id;
          break;
        }
        break;
      case 55: // Medife
        // Módulos consultorio, especialidad y REV no requieren autorización previa
        if (
          (
            (formData.practice_id == 7 || formData.practice_id == 8 || formData.practice_id == 17) &&
            (formData.additional_id != 11 && formData.additional_id != 13)
          ) &&
          !formData.extended_quota || (formData.extended_quota && formData.authorization_id != '')
        ) {
          withoutPrevAuth = true;
          break;
        }
        console.debug(formData.extended_quota);
        console.debug(formData.authorization_id);

        // RPG sólo requiere autorización si es domicilio o con plan cobre, niquel, bronce, titanio y plata
        if (formData.practice_id == 9 && // RPG
          (formData.additional_id != 11 && formData.additional_id != 13)&& // Domicilio
          !formData.recipient_plan.toUpperCase().includes('COBRE') && // Plan cobre
          !formData.recipient_plan.toUpperCase().includes('NIQUEL') && // Plan niquel
          !formData.recipient_plan.toUpperCase().includes('BRONCE') && // Plan bronce
          !formData.recipient_plan.toUpperCase().includes('TITANIO') && // Plan titation
          !formData.recipient_plan.toUpperCase().includes('PLATA') // Plan plata
        ) {
          withoutPrevAuth = true;
          break;
        }

        // Discapacidad e internación requieren número de autorización sólo la primera vez, después lo recupera de la solicitud
        if (
          formData.impairment ||
          (formData.practice_id == 14 && (!formData.additional_id || formData.additional_id == -1))
        ) {
          withoutPrevAuth = !!formData.authorization_id;
          break;
        }

        // Todo lo demás requiere autorización
        withoutPrevAuth = false;
        break;
      case 57: // Swiss Medical
        // Para discapacidad sólo se solicita el número de autorización si no está ya cargado en la solicitud
        if (formData.impairment) {
          withoutPrevAuth = !!formData.authorization_id;
          withToken = false;

        // Swiss Medical no puede utilizar autorización previa para Módulo Consultorio (id 7) ni Práctica Domicilio (id 13)
        } else if (!!formData.practice_id && [7, 13].includes(formData.practice_id)) {
          withoutPrevAuth = true;

        // Swiss Medical no utiliza autorización previa para nomenclador 25010227
        } else if (!!formData.practice_id && [15, 38, 39, 40].includes(formData.practice_id)) {
          withoutPrevAuth = true;

        // No se tiene que pedir token para prácticas de internación porque es fijo 666
        } else if (!!formData.practice_id && formData.practice_id === 14 || formData.additional_id === 14) {
          withToken = false;
        }
        break;
      case 66: // Sancor
        if (formData.previously_authorized && formData.authorization_id) {
          withoutPrevAuth = true;
        } else {
          withoutPrevAuth = false;
        }
        break;
      case 74: // Federada
        withToken = formData.practice_id != 14;
        withoutPrevAuth = false;
        break;
      case 77: // OSPe
        if (quotaExceeded) {
          withoutPrevAuth = false;
        }
        break;
      default:
    }

    setHavePreviousAuthorization(insurersWithPreviousAuthorization.includes(formData.insurer_id) && !withoutPrevAuth);

    setShowCoinsurance(insurersWithCoinsurance.includes(formData.insurer_id));

    setAnnulsAllSessions(insurersAnnulsAllSessions.includes(formData.insurer_id));

    setShowToken(withToken);
  }, [
    formData.insurer_id, // Obra Social
    formData.practice_id, // Módulo seleccionada
    formData.additional_id, // Adicional seleccionado
    quotaExceeded, // Flag de cupo excedido
    formData.impairment, // Prestación de discapacidad
    formData.previously_authorized, // Autorización previa
    formData.authorization_id, // Número de autorización solicitud
  ]);

  useEffect(() => {
    /**
     * Prácticas:
     * - 7 -> Modulo Consultorio
     * - 9 -> Reeducación postural
     * - 10 -> Modulo Drenaje Linfático
     * - 38 -> Especialidad Ortopedia y Traumatologia
     * - 39 -> Estimulación temprana y psicomotricidad
     * - 40 -> Especialidad Deportologia
     * - 15 -> Especialidad Pediatria y Neonatologia
     * - 25 -> Especialidad Intensivista
     */

    // Para las solicitudes de COMEI con autorización previa o necesita cupo extendido es obligatorio el código de autorización la primera vez
    if (formData.insurer_id == 22 && // COMEI
        (formData.previously_authorized || quotaExceeded || !!formData.extended_quota) && // Con autorización Previa o Necesita cupo extendido
        !formData.authorization_id?.trim() // Sin número de autorización
    ) {
      setRequiredPreviousAuthorization(true);
      return;

    // AMFFA requiere número de autorización previa para RPG
    } else if (formData.insurer_id == 26 &&
      formData.practice_id && // Módulo elegido
      [9].includes(formData.practice_id) && // RPG
      !formData.authorization_id?.trim() // Sin número de autorización
    ) {
      setRequiredPreviousAuthorization(true);
      return;

    // Si Medife requiere autorización es obligatoria
    } else if (formData.insurer_id == 55) {
      setRequiredPreviousAuthorization(havePreviousAuthorization);
      return;

    // Algunas prácticas de Swiss medical requieren si o si número de autorización obligatoria
    } else if (formData.insurer_id === 57 && // Swiss Medical
        formData.practice_id && // Módulo elegido
        [9, 10].includes(formData.practice_id) && // Módulo es RPG o DL
        !formData.impairment // No es discapacidad
    ) {
      setRequiredPreviousAuthorization(true);
      return;

    // Si es discapacidad pero todavía no tiene seteado el número de autorización
    } else if (formData.insurer_id === 57 && // Swiss Medical
        formData.impairment && // Es discapacidad
        !formData.authorization_id?.trim() // Sin número de autorización
    ) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para las especialidades y orientaciones de OSPe es obligatoria la autorización previa
    } else if (formData.insurer_id === 77 && formData.practice_id !== 7) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para el módulo consultorio de OSPe es obligatoria la autorización previa si se excedió el cupo
    } else if (formData.insurer_id === 77 && formData.practice_id === 7 && quotaExceeded) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para las Prácticas Domicilio/Internación o falta de cupo de OSPe es obligatoria la autorización previa
    } else if (formData.insurer_id === 77 &&
      (formData.additional_id && [13, 14].includes(formData.additional_id) || quotaExceeded)) {
      setRequiredPreviousAuthorization(true);
      return;
    }

    setRequiredPreviousAuthorization(false);
  }, [
    formData.insurer_id,
    formData.practice_id,
    quotaExceeded,
    formData.previously_authorized,
    formData.impairment,
    formData.authorization_id,
    havePreviousAuthorization,
  ]);

  useEffect(() => {
    let enabled = !!formData.prescription_date && !!formData.recipient_id;

    if (
      ![22, 26, 39, 55, 57].includes(formData.insurer_id) || // Todas las obras sociales menos COMEI, AMFFA, COESBA, Medife y Swiss
      (formData.insurer_id == 22 && !formData.impairment) || // Solicitudes de discapacidad de COMEI no llevan práctica
      (formData.insurer_id == 26 && !formData.impairment) || // Solicitudes de discapacidad de AMFFA no llevan práctica
      (formData.insurer_id == 39 && !formData.impairment) || // Solicitudes de discapacidad de COESBA no llevan práctica
      (formData.insurer_id == 55 && !formData.impairment) || // Solicitudes de discapacidad de Medife no llevan práctica
      (formData.insurer_id == 57 && !formData.impairment) // Solicitudes de discapacidad de Swiss no llevan práctica
    ) {
      enabled = enabled && !!formData.practice_id;
    }

    // UP requiere consultorio
    if (formData.insurer_id == 46) {
      enabled = enabled && !!formData.consultorio_id;
    }

    // Federada requiere diagnósitico
    if (formData.insurer_id == 74) {
      enabled = enabled && !!formData.diagnosis.trim();
    }

    setEnabled(enabled);
  }, [
    formData.prescription_date,
    formData.recipient_id,
    formData.consultorio_id,
    formData.practice_id,
    formData.diagnosis,
    formData.impairment,
  ]);

  useEffect(() => {
    // Si se desactivó la autorización previa, borramos el dato ingresado
    if (!enablePreviousAuthorization) {
      setNewSession({
        ...newSession,
        authorization_id: null,
      });
    }
  }, [enablePreviousAuthorization]);

  const handleSessionAuthorization = async () => {
    try {
      setLoading(true);

      let count = 0;
      const newSessionDate = moment(newSession.date);
      for (const currentSession of formData.sessions) {
        if (newSessionDate.isSame(currentSession.date, 'day')) {
          count += 1;
        }
      }

      let sessionsPerDay = 1;
      if (formData.insurer_id === 57 && (formData.practice_id === 14 || formData.additional_id === 14)) {
        sessionsPerDay = 2;
      } else if (formData.insurer_id === 55 && (formData.practice_id === 14 || formData.additional_id === 14)) {
        sessionsPerDay = 3;
      }

      if (count > sessionsPerDay) {
        if (sessionsPerDay === 1) {
          alert('No se puede agregar más de una sesión por día');
        } else {
          alert(`No se puede agregar más de ${ sessionsPerDay } sesiones por día`);
        }
        return;
      }

      // Verificar que se haya ingresado el número de autorización en los casos que corresponda
      if (
        (requiredPreviousAuthorization || enablePreviousAuthorization) &&
        (!newSession?.authorization_id || !newSession.authorization_id?.trim())
      ) {
        alert('Ingrese el número de autorización previa en el cuadro de texto correspondiente e intente nuevamente.');
        return;
      }

      // Impide que se cree una sesión con el token vacío
      if (showToken &&
        (newSession.token === null || newSession.token?.trim() === '') &&
        formData.insurer_id !== 57 && // Para Swiss no es obligatorio
        formData.insurer_id !== 74 // Para Federada no es obligatorio
      ) {
        alert('Debe ingresar un token.');
        return;
      }

      // Impedir el uso del token de pruebas de Swisss por parte de los matriculados
      if (showToken && // Token habilitado
        formData.insurer_id === 57 && // Obra social Swiss
        newSession.token && ['555a', '666', '999'].includes(newSession.token) // Tokens bloqueados
      ) {
        alert('El número de token ingresado no es válido.');
        return;
      }

      const response = await post('insurers/authorize', {
        insurer_id: formData.insurer_id,
        request_id: requestId,
        previously_authorized: formData.previously_authorized,

        prescriber_id: user?.getMatricula(),
        recipient_id: formData.recipient_id,

        practice_id: formData.practice_id,
        practice_text: prestaciones.modules.find(p => p.id === parseInt(`${ formData.practice_id }`))?.name,

        practice_authorization: newSession.authorization_id,
        practice_date: newSession.date
          ? moment(newSession.date).format('YYYY-MM-DD')
          : undefined,
        practice_token: newSession.token,

        authorization_id: formData.authorization_id ? formData.authorization_id : undefined,
        extended_quota: formData.extended_quota ? formData.extended_quota : undefined,

        consultorio_id: formData.consultorio_id ? formData.consultorio_id : undefined,

        additional_id: formData.additional_id,
        additional_text: formData.additional_id
          ? prestaciones.additionals.find(p => p.id === parseInt(`${ formData.additional_id }`))?.name
          : null,

        diagnosis: formData.diagnosis,

        quirurgical: formData.quirurgical,
        impairment: formData.impairment,

        prescription_date: formData.prescription_date
          ? moment(formData.prescription_date).format('YYYY-MM-DD')
          : undefined,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.code === 'REQUIRE_AUTHORIZATION') {
          setQuotaExceeded(true);
          formData.extended_quota = true;
          if (formData.insurer_id == 22) {
            alert('Tope superado. \n\rCerrar e ingresar el nro. de disposición emitido por el Comei.');
          } else {
            alert(response.message);
          }
          return;
        }

        if (response.message) {
          alert(response.message);
          return;
        }

        alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
        return;
      }

      if (response.payload?.recipient?.name) {
        setRecipient(response.payload.recipient.name);
      }

      if (
        // En el caso de discapacidad Medife si no está seteado aún guardamos el número de autorización
        (formData.insurer_id === 55 && setFormData && formData.impairment && !formData.authorization_id) ||
        (formData.insurer_id === 55 && setFormData && formData.practice_id == 14) ||
        // Medife en caso de ser un cupo extendido
        (formData.insurer_id === 55 && setFormData && formData.extended_quota) ||
        // En el caso de discapacidad Swiss si no está seteado aún guardamos el número de autorización
        (formData.insurer_id === 57 && setFormData && formData.impairment && !formData.authorization_id) ||
        // En el caso de autorización previa COMEI si no está seteado aún guardamos el número de autorización
        (formData.insurer_id === 22 && setFormData && !formData.authorization_id) ||
        // En el caso de autorización previa AMFFA si no está seteado aún guardamos el número de autorización
        (formData.insurer_id === 26 &&
          setFormData &&
          formData.practice_id &&
          [9].includes(formData.practice_id) && // RPG
          !formData.authorization_id)
      ) {
        setFormData({
          ...formData,
          sessions: [
            ...formData.sessions,
            {
              date: moment(response.payload.session.date, 'YYYY-MM-DD').toDate(),
              date_parsed: moment(response.payload.session.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              authorization_id: response.payload.session.approvalNumber,
              coinsurance: response.payload.session.coinsurance,
            } as Session,
          ],
          authorization_id: newSession.authorization_id
            ? newSession.authorization_id
            : response.payload.billing_request.numero_aprobacion,
        });

      // Todas las demas obras sociales y otras solicitudes de Swiss
      } else {
        // Agregamos al arreglo de fechas de sesiones la nueva fecha
        setSessions([
          ...formData.sessions,
          {
            date: moment(response.payload.session.date, 'YYYY-MM-DD').toDate(),
            date_parsed: moment(response.payload.session.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            authorization_id: response.payload.session.approvalNumber,
            coinsurance: response.payload.session.coinsurance,
          } as Session,
        ]);
      }

      setOpen(false);

      if (response.payload.session.coinsurance > 0) {
        alert(`Coseguro a cargo del afiliado por sesión: $ ${ response.payload.session.coinsurance }`);
      }
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const handleSessionAnnulment = async (target: number) => {
    try {
      setLoading(true);

      const response = await post('insurers/annul', {
        request_id: requestId,
        insurer_id: formData.insurer_id,
        recipient_id: formData.recipient_id,
        authorization_id: formData.sessions[target].authorization_id,
        session_date: formData.sessions[target].date_parsed,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar anular la práctica. Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      if (annulsAllSessions) {
        setSessions([]);
      } else {
        const sessions = formData.sessions.filter((value, idx) => idx !== target);
        setSessions(sessions);
      }
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el número de beneficiario. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const showNewSessionModal = async () => {
    if (!isOriginal) {
      const response = await fetch(`${ config.baseUrl }/requests/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCookie('accessToken'),
        },
        body: JSON.stringify({
          request_id: requestId,
          insurer_id: formData.insurer_id,
          recipient_id: formData.recipient_id,
          practice_id: formData.practice_id,
          additional_id: formData.additional_id && formData.additional_id != -1 ? formData.additional_id : undefined,
          prescription_date: formData.prescription_date
            ? moment(formData.prescription_date).format('YYYY-MM-DD')
            : undefined,
        }),
      });

      const output = await response.json();

      if (output.status === 'ok' && output.payload) {
        if (output.payload.estado_id === 1 || output.payload.estado_id === 4) {
          alert(`Ya existe una solicitud en curso para este afiliado con esta fecha de prescripción. ` +
            `Si desea agregar más sesiones al tratamiento debe hacerlo en la misma.` +
            `Ahora será redirigido a la solicitud #${ output.payload.id }`, () => {
            if (formData.insurer_id === 22) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-comei`);
            } else if (formData.insurer_id === 26) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-amffa`);
            } else if (formData.insurer_id === 46) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-up`);
            } else if (formData.insurer_id === 66) {
              if (formData.previously_authorized) {
                history.replace(`/solicitudes/${ output.payload.id }/edit-sancor-4`);
              } else {
                history.replace(`/solicitudes/${ output.payload.id }/edit-sancor`);
              }
            } else if (formData.insurer_id === 55) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-medife`);
            } else if (formData.insurer_id === 57) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-swiss`);
            } else if (formData.insurer_id === 74) {
              if (formData.previously_authorized) {
                history.replace(`/solicitudes/${ output.payload.id }/edit-federada-p`);
              } else {
                history.replace(`/solicitudes/${ output.payload.id }/edit-federada`);
              }
            }
            history.replace(`/solicitudes/${ output.payload.id }/edit-sessions`);
          });

          return;
        }
        if (output.payload.estado_id === 2) {
          alert(`Verifique la Solicitud ${ output.payload.id } enviada al auditor. Por favor, comunicarse con la DR ` +
          `para solicitar su retorno a su bandeja de tratamientos para dar continuidad al mismo`);
          return;
        }
      }

      setIsOriginal(true);
    }

    // Calculamos la fecha mínima para las sesiones
    let newMinDate: Moment;
    switch (formData.insurer_id) {
      case 22: // COMEI
      case 66: // Sancor
        newMinDate = moment().subtract(6, 'days');
        break;
      case 46: // UP
        newMinDate = moment().subtract(5, 'days');
        break;
      case 77: // OSPe
        // Fecha mínima OSPe 5 días
        newMinDate = moment().subtract(4, 'days');
        break;
      case 74: // Federada
        // Fecha mínima: Federada 7 días excepto internación
        if (formData.practice_id === 14) {
          newMinDate = moment().subtract(364, 'days');
        } else {
          newMinDate = moment().subtract(6, 'days');
        }
        break;
      case 26: // AMFFA
      case 39: // COESBA
      case 55: // Medife
      case 57: // Swiss
        if (formData.impairment) {
          newMinDate = moment().subtract(364, 'days');
        } else {
          newMinDate = moment().subtract(6, 'days');
        }
        break;
      default:
        newMinDate = formData?.prescription_date
          ? moment(formData.prescription_date)
          : moment();
    }

    // Si la fecha de prescripción es mayor a la fecha mínima, ajustamos la fecha mínima
    if (formData?.prescription_date && newMinDate.isBefore(formData.prescription_date, 'day')) {
      newMinDate = moment(formData.prescription_date);
    }

    // Calculamos la fecha máxima para las sesiones
    let newMaxDate = moment();

    // Las sesiones de una prestación por discapacidad de Swiss tienen que estar dentro del mismo año calendario
    if (formData.insurer_id == 57 && formData.impairment && formData.sessions.length > 0) {
      const firstSessionDate = formData.sessions
        .map(({ date }) => date)
        .sort((a, b) => a.valueOf() - b.valueOf())[0];

      // Si el nuevo máximo se va del año de la primera sesión lo ajustamos
      if (newMaxDate.isAfter(firstSessionDate, 'year')) {
        newMaxDate = moment(firstSessionDate).endOf('year');
      }
    }


    // AMFFA, Medife, COMEI, COESBA, UP, Sancor y OSAM si el mes es diferente ajustamos la fecha mínima al primer día del
    // mes en curso excepto en el caso de discapacidad (sólo COMEI, Sancor y OSAM)
    if (
      (formData.insurer_id == 39 && !formData.impairment) || // COESBA excepto discapacidad
      (formData.insurer_id == 55 && !formData.impairment) || // Medife
      (formData.insurer_id == 26 && !formData.impairment) || // AMFFA
      (formData.insurer_id == 22) || // COMEI
      (formData.insurer_id == 46) || // UP
      (formData.insurer_id == 66) || // Sancor
      (formData.insurer_id == 44) // OSAM
    ) {
      if (!newMinDate.isSame(moment(), 'month')) {
        newMinDate = moment().startOf('month');
      }

      if (!newMaxDate.isSame(moment(), 'month')) {
        newMaxDate = moment().endOf('month');
      }
    }

    // Si la fecha es anterior a 24/06/2024 se pone esa como mínima (fecha de puesta en producción)
    if (formData.insurer_id == 44) {
      const deployDate = moment('20240624', 'YYYYMMDD');
      newMinDate = newMinDate.isBefore(deployDate, 'day') ? deployDate : newMinDate;
    }

    // Calculamos la fecha sugerida
    let suggestedDate: Moment | null = newMinDate;

    // Si hay fechas de sesión tomamos el día siguiente a la última fecha
    if (formData.sessions.length) {
      const lastSessionDate = moment(formData.sessions
        .map(({ date }) => date)
        .sort((a, b) => {
          return b.valueOf() - a.valueOf();
        })[0]);

      if (lastSessionDate.clone().add(1, 'day').isSameOrBefore(moment(), 'day')) {
        suggestedDate = lastSessionDate.add(1, 'day');
      }
    }

    // Controlamos que la fecha sugerida esté entre los límites
    const invalidMin = suggestedDate.isBefore(newMinDate, 'day');
    const invalidMax = suggestedDate.isAfter(newMaxDate, 'day');
    if (invalidMin && invalidMax) {
      return;
    }

    if (invalidMin) {
      suggestedDate = newMinDate.clone();
    } else if (invalidMax) {
      suggestedDate = newMaxDate.clone();
    }

    let count = 0;
    for (const currentSession of formData.sessions) {
      if (suggestedDate.isSame(currentSession.date, 'day')) {
        count += 1;
      }
    }

    // Cantidad de sesiones por día
    let sessionsPerDay = 1;
    if (formData.insurer_id === 57 && (formData.practice_id === 14 || formData.additional_id === 14)) {
      sessionsPerDay = 2;
    } else if (formData.insurer_id === 74 && (formData.practice_id === 14 || formData.additional_id === 14)) {
      sessionsPerDay = 2;
    } else if (formData.insurer_id === 55 && (formData.practice_id === 14 || formData.additional_id === 14)) {
      sessionsPerDay = 3;
    }

    // Si ya está esa cantidad de sesiones cargadas no hacemos la sugerencia
    if (count >= sessionsPerDay) {
      suggestedDate = null;
    }

    setNewSessionMinDate(newMinDate.toDate());
    setNewSessionMaxDate(newMaxDate.toDate());

    let newSessionToken: typeof newSession.token = null;

    if (formData.insurer_id === 57 && (formData.practice_id === 14 || formData.additional_id === 14)) {
      newSessionToken = '666';
    }

    setNewSession({
      date: suggestedDate ? suggestedDate.toDate() : null,
      token: newSessionToken,
      authorization_id: null,
    });

    // Mostramos el modal
    setOpen(true);
  };

  return (
    <>
      <Button
        className="d-block"
        color="primary"
        onClick={ () => {
          showNewSessionModal();
        } }
        disabled={ isLoading || !isEnabled }
      >
        Agregar sesión
      </Button>
      <small style={{ color: '#e67f83' }} className={`mt-2 ${ formData?.prescription_date ? 'd-none' : 'd-block' }`}>
        Falta seleccionar la fecha de prescripción
      </small>
      <small style={{ color: '#e67f83' }} className={`mt-2 ${ formData?.recipient_id ? 'd-none' : 'd-block' }`}>
        Falta indicar el número de afiliado
      </small>
      <Table className='align-items-center table-flush mb-0' style={{ fontSize: '.8rem' }} responsive={ false }>
        <thead>
          <tr>
            <th>FECHA SESIÓN</th>
            <th>AUTORIZACIÓN</th>
            { showCoinsurance ? <th>COSEGURO</th> : null }
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {
            formData && formData.sessions && Array.isArray(formData.sessions)
              ? formData.sessions
                .sort((a, b) => a.date.valueOf() - b.date.valueOf())
                .map(({ date, authorization_id: authorizationId, coinsurance, online }, i) => (
                  <tr key={ i }>
                    <td>{ date.toLocaleDateString('es') }</td>
                    <td>{ authorizationId ?? ' - ' }</td>
                    { showCoinsurance ? <td>$ { coinsurance ?? '0.00' }</td> : null }
                    <td className='text-left table__mobile--body__actions'>
                      {
                      // Sancor devuelve número de autorización 0 en Test
                        (!annulsAllSessions || i == 0) &&
                        (
                          authorizationId ||
                          (formData.insurer_id === 66 && authorizationId !== '') ||
                          (formData.insurer_id === 74 && !online)
                        )
                          ? <Button
                              color="warning"
                              size="sm"
                              onClick={ () => handleSessionAnnulment(i) }
                              disabled={ isLoading }
                            >{ !annulsAllSessions ? 'Anular' : 'Anular Todas' }</Button>
                          : null
                      }
                    </td>
                  </tr>
                ))
              : null
          }
        </tbody>
      </Table>
      <Modal isOpen={ isOpen } size="md" centered>
        <ModalHeader>Agregar fecha de sesión</ModalHeader>
        <ModalBody>
          <FormGroup className='card form-group-dates'>
            <Label>Fecha</Label>
            <DatePicker
              id="session_date"
              name="session_date"
              onChange={ date => {
                if (!date) {
                  date = new Date();
                }

                setNewSession({ ...newSession, date: new Date(date.setHours(0, 0, 0, 0)) });
              }}
              dateFormat="dd/MM/yyyy"
              locale="es"
              placeholderText="Fecha de la sesión"
              minDate={ newSessionMinDate }
              maxDate={ newSessionMaxDate }
              excludeDates={ excludedDates }
              selected={ newSession.date }
              className="form-control"
              autoComplete="off"
              disabled={ isLoading }
            />
          </FormGroup>
          {
            havePreviousAuthorization
              ? <FormGroup>
                  <div className="custom-control custom-checkbox">
                    <Input
                      id="session_have_authorization"
                      name="session_have_authorization"
                      type="checkbox"
                      checked={ requiredPreviousAuthorization || enablePreviousAuthorization }
                      onChange={ ({ target }) => setEnablePreviousAuthorization(target.checked) }
                      disabled={ isLoading }
                      style={{ marginTop: '.15rem' }}
                    />
                    <Label for="session_have_authorization" className="form-check-label">
                      { previousAuthorizationLabel }
                    </Label>
                  </div>
                  <Input
                    id="session_authorization_id"
                    name="session_authorization_id"
                    value={ newSession.authorization_id ?? '' }
                    onChange={ ({ target }) => setNewSession({ ...newSession, authorization_id: target.value }) }
                    disabled={ isLoading || (!requiredPreviousAuthorization && !enablePreviousAuthorization)}
                    required
                  />
                </FormGroup>
              : null
          }
          {
            showToken
              ? <FormGroup>
                  <Label for="session_token">Token de Seguridad</Label>
                  <Input
                    id="session_token"
                    name="session_token"
                    value={ newSession.token ? newSession.token : '' }
                    onChange={ ({ target }) => setNewSession({ ...newSession, token: target.value.trim() }) }
                    disabled={ isLoading }
                    required
                  />
                </FormGroup>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={ handleSessionAuthorization }
            disabled={ isLoading || disableSend || !newSession.date }
          >
            { requiredPreviousAuthorization || enablePreviousAuthorization ? 'Validar' : 'Autorizar' }
          </Button>
          <Button
            color="primary"
            onClick={ () => setOpen(false) }
            disabled={ isLoading }
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  );
}
