import React, { useEffect, useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useAlertPlus, useConfirm } from 'hooks';
import Required from 'components/Required';
import DatePicker, { DatePickerChangeEvent } from 'components/DatePicker';
import ConfirmModal from 'components/ConfirmModal';
import AlertModal from 'components/AlertModal';
import { useUser } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import Attachments from 'components/Requests/Attachments';
import ObservationsAlert from 'components/Requests/ObservationAlert';
import Sessions from '../../components/Requests/Sessions';
import {
  Request,
  RequestFiles,
  InsurerPractices,
  AttachmentType,
  Session,
} from 'types/Request';
import {
  getRequest,
  updateRequest,
  createObservation,
} from 'features/requests/requestsAPI';
import { getPractices } from 'features/practices/practicesAPI';
import ObservationList from 'components/Requests/ObservationList';
import moment from 'moment';
import { getVencimiento } from 'helpers/vencimientos';

function EditSwiss() {
  const { id } = useParams();

  const history = useHistory();

  const { getMatricula } = useUser();
  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const [request, setRequest] = useState<Request>();

  const [files, setFiles] = useState<RequestFiles>({});

  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [formValidation, setFormValidation] = useState<Partial<Record<keyof Request, boolean>>>({});

  const [disableSend, setDisableSend] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  const [infoVencimiento, setInfoVencimiento] = useState('');

  const fetchPrestaciones = async (newRequest?: Request) => {
    try {
      if (!request && !newRequest) {
        return;
      }

      setLoading(true);

      const currentRequest = newRequest ? newRequest : request!;

      const data = await getPractices({
        insurerId: currentRequest.insurer_id,
        matriculateId: parseInt(matriculateId),
        date: currentRequest.prescription_date
          ? moment(currentRequest.prescription_date).format('YYYY-MM-DD')
          : undefined,
      });

      if (!data) {
        throw new Error(
          'Hay problemas de conexión con el sistema de Facturación. ' +
            'Temporalmente no se podrá enviar la solicitud.',
        );
      }

      setPrestaciones({
        modules: data.modules ?? [],
        additionals: data.additionals ?? [],
      });
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    getRequest(parseInt(id))
      .then(response => {
        if (!response) {
          return;
        }

        const { request, files: newFiles } = response;

        if (!request.id || !request.insurer_id) {
          alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
              'Por favor, intente nuevamente más tarde',
          () => {
            window.location.href = '/solicitudes';
          });
          console.error('EditSwiss', `No se pudo recuperar información sobre la solicitud #${id}`);
          console.info(request);
          return;
        }

        setFiles(newFiles);
        setRequest(request);
        fetchPrestaciones(request);
      })
      .catch(err => {
        setDisableSend(true);
        alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
            'Por favor, intente nuevamente más tarde');
        console.error('EditSwiss', 'Error inesperador al intentar recuperar la presentación');
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (!request || !request.additional_id) {
      return;
    }

    // Si entre los adicionales no se encuentra la práctica actual, borramos la selección
    if (prestaciones.additionals.findIndex(p => p.id === request.additional_id) === -1) {
      setRequest({
        ...request,
        additional_id: undefined,
      });
    }
  }, [prestaciones.additionals]);

  // Cálculo de días para el vencimiento
  useEffect(() => {
    if (!request?.sessions) {
      setInfoVencimiento('');
      return;
    }

    const diffDays = getVencimiento(request.sessions);
    if (!diffDays) {
      setInfoVencimiento('');
      return;
    }

    if (diffDays > 60 ) {
      setInfoVencimiento('Solicitud vencida');
    } else {
      setInfoVencimiento(`Restan ${ 60 - diffDays } días para que se venza la solicitud`);
    }
  }, [request?.sessions.length]);

  if (!request) {
    return <>Cargando...</>;
  }

  // Guardado de borrador
  const handleBillingDraft = async () => {
    setFormValidation({});

    setLoading(true);

    updateRequest(
      {
        ...request,
        status_id: 1,
      },
      files,
      prestaciones,
    )
      .then(res => {
        if (!res || !res.request.status_id || res.request.status_id !== 1) {
          console.error('EditSwiss', 'Error al enviar la solicitud');
          console.info(res);
          alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
          setLoading(false);
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('EditSwiss', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
        setLoading(false);
      });
  };

  const handleFormValidation = () => {
    let newFormValidation = {};

    let toValidate = [
      ...document.getElementsByClassName('form-control'),
    ] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else {
        field.classList.remove('is-invalid');
      }
    });

    // Validar file inputs required
    toValidate = [
      ...document.getElementsByClassName('custom-file-input'),
    ] as HTMLInputElement[];
    toValidate.forEach(field => {
      if (
        field.required &&
        !field.disabled &&
        !files[field.dataset.key as AttachmentType]
      ) {
        field.classList.add('is-invalid');

        missing = true;
      }
    });

    // Validar cantidad de sesiones
    if (request.sessions.length === 0) {
      newFormValidation = {
        ...newFormValidation,
        sessions: false,
      };

      missing = true;
    }

    if (missing) {
      setFormValidation(newFormValidation);
      alert('Todos los campos marcados con * son obligatorios. ' +
        'Por favor complete los faltantes antes de enviar la solicitud.',
      );
      return false;
    }

    return true;
  };

  // Confirmación de envío de Solicitud
  const handleBillingConfirmation = () => {
    if (!handleFormValidation()) {
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas enviar esta solicitud?', 'billing');
  };

  // Gestor de respuestas de confirmaciones
  const handleConfirmation = (action: string) => {
    if (action === 'billing') {
      handleBillingRequest();
    }

    setConfirm(false);
  };

  // Envío de solicitudes
  const handleBillingRequest = () => {
    setFormValidation({});

    setLoading(true);

    updateRequest(
      {
        ...request,
        status_id: 2,
      },
      files,
      prestaciones,
    )
      .then(res => {
        if (!res || !res.request.status_id) {
          // Error interno
          console.error('EditSwiss', 'Error al enviar la solicitud');
          console.info(res);
          alert(`Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.`);
          return;
        } else if (res.request.status_id === 10) {
          // Fuera de término
          alert(`La solicitud ${request.id} se encuentra fuera de término`,
            () => {
              history.push('/solicitudes');
            },
          );
          return;
        } else if (res.request.status_id !== 3) {
          // Otros errores
          console.error('EditSwiss', 'Error al enviar la solicitud');
          console.info(res);
          alert(
            'Ha ocurrido un error al procesar la solicitud enviada. Por favor intente nuevamente.',
          );
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('EditSwiss', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error inesperado al enviar la solicitud. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | DatePickerChangeEvent) => {
    const { target } = event;

    const name = target.name;

    let value: string | boolean | number | Date | null;
    value = target.type === 'checkbox' ? target.checked : target.value;
    if (typeof value === 'string' && ['insurer_id', 'practice_id', 'additional_id'].includes(name)) {
      value = parseInt(value);
    }

    const extra: Partial<Request> = {};

    // Si cambió el número de afiliado, borramos el nombre
    if (name === 'recipient_id') {
      extra['recipient_name'] = undefined;
    }

    // Guardamos el nuevo valor
    setRequest({
      ...request,
      ...extra,
      [name]: value,
    });
  };

  const setSessions = (sessions: Session[]) => {
    setRequest({
      ...request,
      sessions,
    });
  };

  const setRecipient = (name: string) => {
    setRequest({
      ...request,
      recipient_name: name,
    });
  };

  const handleNewObservation = async (obserbation: string) => {
    setLoading(true);

    const result = await createObservation(request, obserbation);

    if (result.status === 'ok') {
      setRequest({
        ...request,
        observations: result.request.observations,
      });
    }

    setLoading(false);

    return result.status === 'ok';
  };

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">
                    Presentación #{id}
                    {
                      request?.insurer_shortname
                        ? ' - ' + request.insurer_shortname
                        : ' '
                    }
                    <br />
                    <span style={{ fontSize: '1rem' }}>{ infoVencimiento }</span>
                  </CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => history.push('/solicitudes')}
                  >
                    Volver
                  </Button>
                </Col>
              </Row>
              <ObservationsAlert observations={request.observations} />
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="recipient_id">
                        Número de Afiliado <Required />
                      </Label>
                      <Input
                        id="recipient_id"
                        name="recipient_id"
                        value={ request.recipient_id ?? '' }
                        invalid={ formValidation.recipient_id === false }
                        onChange={ handleInputChange }
                        disabled={
                          isLoading || // Cargando
                          request.recipient_validated || // Ya validado
                          request.sessions.length > 0 // Sesiones cargadas
                        }
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {
                  request.recipient_name
                    ? <FormGroup>
                        <Row form>
                          <Col sm={6}>
                            <Label for="recipient_name">Nombre del Afiliado</Label>
                            <Input
                              id="recipient_name"
                              name="recipient_name"
                              defaultValue={ request.recipient_name }
                              disabled={
                                isLoading || // Cargando
                                request.recipient_validated // Ya validado
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    : null
                }
                <FormGroup>
                  <Row form>
                    <Col sm={4}>
                      <Label for="prescription_date">
                        Fecha Prescripción Médica <Required />
                      </Label>
                      <DatePicker
                        id="prescription_date"
                        name="prescription_date"
                        selected={request.prescription_date ?? ''}
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        placeholderText="Fecha Prescripción"
                        className="date-picker form-control"
                        maxDate={ request.authorization_date ?? new Date() }
                        minDate={ moment().subtract(60, 'days').toDate() }
                        disabled={
                          isLoading || // Si está cargando
                          (request.previously_authorized && request.authorization_validated) || // Si es con autorización previa ya verificada
                          request.sessions.length > 0 // Si hay sesiones cargadas
                        }
                        onChange={date => {
                          handleInputChange({
                            target: {
                              name: 'prescription_date',
                              type: 'datepicker',
                              value: date,
                            },
                          });
                        }}
                        autoComplete="off"
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="diagnosis">
                    Diagnóstico <Required />
                  </Label>
                  <Input
                    id="diagnosis"
                    name="diagnosis"
                    type="textarea"
                    invalid={formValidation.diagnosis === false}
                    value={request.diagnosis ?? ''}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    required
                  />
                </FormGroup>
                <FormGroup row>
                  <Col md="6">
                    <Label for="practice_id">
                      Tipo de práctica <Required />
                    </Label>
                    <Input
                      id="practice_id"
                      name="practice_id"
                      type="select"
                      className="custom-select"
                      value={request.practice_id ?? ''}
                      invalid={formValidation.practice_id === false}
                      onChange={handleInputChange}
                      disabled={
                        isLoading || // Si está cargando
                        (request.previously_authorized && request.authorization_validated) || // Si es con autorización previa ya verificada
                        request.sessions.length > 0 // Si hay sesiones cargadas
                      }
                      required
                    >
                      <option disabled value="" hidden>
                        Seleccione una opción...
                      </option>
                      {
                        prestaciones.modules.map(item => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })
                      }
                    </Input>
                  </Col>
                  {
                    prestaciones.additionals.length > 0
                      ? <Col sm={6}>
                          <Label for="additional_id">Práctica adicional</Label>
                          <Input
                            id="additional_id"
                            name="additional_id"
                            type="select"
                            className="custom-select"
                            value={request.additional_id ?? ''}
                            invalid={formValidation.additional_id === false}
                            onChange={handleInputChange}
                            disabled={
                              isLoading || // Si está cargando
                              !request.practice_id || // Si todavía no se seleccionó una práctica
                              (request.previously_authorized && request.authorization_validated) || // Si es con autorización previa ya verificada
                              request.sessions.length > 0 // Si hay sesiones cargadas
                            }
                          >
                            <option value="">Ninguna</option>
                            {
                              prestaciones.additionals.map(item => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            }
                          </Input>
                        </Col>
                      : null
                  }
                </FormGroup>
                <FormGroup style={{ paddingLeft: '1.5rem' }}>
                  <Input
                    id="quirurgical"
                    name="quirurgical"
                    type="checkbox"
                    checked={request.quirurgical ?? false}
                    invalid={formValidation.quirurgical === false}
                    onChange={handleInputChange}
                    disabled={
                      isLoading || // Cargando
                      request.previously_authorized || // Tiene autorización previa (discapacidad)
                      request.sessions.length > 0 // Sesiones cargadas
                    }
                  />
                  <Label
                    for="quirurgical"
                    style={{ marginBottom: 0, verticalAlign: 'bottom' }}
                  >
                    Es post quirúrgico
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label>Fechas de sesiones</Label>
                  <Sessions
                    requestId={id}
                    formData={request}
                    setFormData={setRequest}
                    prestaciones={prestaciones}
                    setSessions={setSessions}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    disableSend={disableSend}
                    setRecipient={setRecipient}
                  />
                </FormGroup>
                <Attachments
                  files={files}
                  setFiles={setFiles}
                  disabled={isLoading}
                  setLoading={setLoading}
                  previouslyAuthorized={false}
                  formData={ request }
                />
                <FormGroup>
                  <Label>Observaciones</Label>
                  <ObservationList
                    observations={request.observations}
                    disabled={isLoading}
                    createObservation={handleNewObservation}
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={handleBillingDraft}
                      disabled={isLoading}
                    >
                      Guardar Borrador
                    </Button>
                    <Button
                      type="button"
                      className="btn-round"
                      onClick={handleBillingConfirmation}
                      disabled={isLoading || disableSend}
                    >
                      Enviar Solicitud
                    </Button>
                  </div>
                </FormGroup>
              </Form>
              <AlertModal
                isOpen={showAlert}
                message={message}
                onClose={alertCallback}
              />
              <ConfirmModal
                isOpen={confirm}
                title={confirmTitle}
                action={confirmAction}
                onClose={() => setConfirm(false)}
                onConfirm={handleConfirmation}
              />
            </CardBody>
            <CardFooter>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}></Col>
                <Col sm={4} className="text-right">
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => history.push('/solicitudes')}
                  >
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EditSwiss;
