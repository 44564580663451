import StepInsurerSelectionRequests from './views/Requests/StepInsurerSelection'
import StepAffiliateValidationRequests from './views/Requests/StepAffiliateValidation'
import StepOnlineAuthorizationRequests from './views/Requests/StepOnlineAuthorization'
import StepAuthorizationValidation from 'views/Requests/StepAuthorizationValidation'

import ListRequests from './views/Requests/List'
import ViewRequests from './views/Requests/View'

import EditAvalian from './views/Requests/EditAvalian'
import EditSessionsRequests from './views/Requests/EditSessions'
import EditSancor from 'views/Requests/EditSancor'
import EditSancor4 from 'views/Requests/EditSancor4'
import EditSwiss from 'views/Requests/EditSwiss'
import EditSwissD from 'views/Requests/EditSwissD'
import EditFederada from 'views/Requests/EditFederada'
import EditCOMEI from 'views/Requests/EditCOMEI'
import EditMedife from 'views/Requests/EditMedife'
import EditAMFFA from 'views/Requests/EditAMFFA'
import EditOSAM from 'views/Requests/EditOSAM'
import EditUP from 'views/Requests/EditUP'

import ListBills from 'views/Bills/List'
import ShowBill from 'views/Bills/Show'
import EditBill from 'views/Bills/Edit'

import Report from 'views/Reports/Reports'
import Help from 'views/Help/Help'
import EditCOESBA from 'views/Requests/EditCOESBA'

var routes = [
  {
    path: '/step-insurer-selection',
    component: StepInsurerSelectionRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-affiliate-validation',
    component: StepAffiliateValidationRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-authorization-validation',
    component: StepAuthorizationValidation,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-online-authorization',
    component: StepOnlineAuthorizationRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sessions',
    component: EditSessionsRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-osam',
    component: EditOSAM,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-medife',
    component: EditMedife,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sancor',
    component: EditSancor,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sancor-4',
    component: EditSancor4,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-swiss',
    component: EditSwiss,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-swiss-d',
    component: EditSwissD,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-federada',
    component: EditFederada,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-coesba',
    component: EditCOESBA,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-comei',
    component: EditCOMEI,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-amffa',
    component: EditAMFFA,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-avalian',
    component: EditAvalian,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-up',
    component: EditUP,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id',
    component: ViewRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '',
    name: "Gestión de Autorización",
    displayName: "Gestión de Autorización",
    icon: "nc-icon nc-box",
    component: ListRequests,
    layout: '/solicitudes'
  },
  {
    path: "/:id/edit",
    name: "Facturación online",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: EditBill,
    layout: '/facturacion',
    hide: true
  },
  {
    path: "/:id",
    name: "Facturas",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: ShowBill,
    layout: '/facturacion',
    hide: true
  },
  {
    path: '',
    name: "Facturación online",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: ListBills,
    layout: '/facturacion',
  },
  {
    path: '',
    name: "Reportes",
    icon: "nc-icon nc-chart-bar-32",
    component: Report,
    layout: '/report'
  },
  {
    path: '',
    name: "Ayuda",
    icon: "nc-icon nc-bulb-63",
    component: Help,
    layout: '/help'
  }
]

export default routes
